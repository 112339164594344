import _define_property from "@swc/helpers/src/_define_property.mjs";
import JSBI from "jsbi";
import { Token } from "./entities/token";
export var ChainId;
(function(ChainId) {
    ChainId[ChainId["BSC"] = 56] = "BSC";
    ChainId[ChainId["BSC_TESTNET"] = 97] = "BSC_TESTNET";
    ChainId[ChainId["DOGECHAIN"] = 2000] = "DOGECHAIN";
})(ChainId || (ChainId = {}));
export var TradeType;
(function(TradeType) {
    TradeType[TradeType["EXACT_INPUT"] = 0] = "EXACT_INPUT";
    TradeType[TradeType["EXACT_OUTPUT"] = 1] = "EXACT_OUTPUT";
})(TradeType || (TradeType = {}));
export var Rounding;
(function(Rounding) {
    Rounding[Rounding["ROUND_DOWN"] = 0] = "ROUND_DOWN";
    Rounding[Rounding["ROUND_HALF_UP"] = 1] = "ROUND_HALF_UP";
    Rounding[Rounding["ROUND_UP"] = 2] = "ROUND_UP";
})(Rounding || (Rounding = {}));
export var FACTORY_ADDRESS_BSC = "0xc7c86B4f940Ff1C13c736b697e3FbA5a6Bc979F9";
export var FACTORY_ADDRESS_BSC_TESTNET = "0x6725f303b657a9451d8ba641348b6761a6cc7a17";
export var FACTORY_ADDRESS_DC = "0xc7c86B4f940Ff1C13c736b697e3FbA5a6Bc979F9";
var _obj;
export var FACTORY_ADDRESS_MAP = (_obj = {}, _define_property(_obj, ChainId.BSC, FACTORY_ADDRESS_BSC), _define_property(_obj, ChainId.BSC_TESTNET, FACTORY_ADDRESS_BSC_TESTNET), _define_property(_obj, ChainId.DOGECHAIN, FACTORY_ADDRESS_DC), _obj);
export var INIT_CODE_HASH_BSC = "0x908a679b76436e6d853df6556fbcb55cbfe69fb45a6ac9fe0c1e0f2a53f7baf4";
export var INIT_CODE_HASH_BSC_TESTNET = "0xd0d4c4cd0848c93cb4fd1f498d7013ee6bfb25783ea21593d5834f5d250ece66";
export var INIT_CODE_HASH_DC = "0x7856aa433cee3d1a7f4b34cd0f0cf0967c2345e951944c19991f4a73d0f5eee6";
var _obj1;
export var INIT_CODE_HASH_MAP = (_obj1 = {}, _define_property(_obj1, ChainId.BSC, INIT_CODE_HASH_BSC), _define_property(_obj1, ChainId.BSC_TESTNET, INIT_CODE_HASH_BSC_TESTNET), _define_property(_obj1, ChainId.DOGECHAIN, INIT_CODE_HASH_DC), _obj1);
export var MINIMUM_LIQUIDITY = JSBI.BigInt(1000);
// exports for internal consumption
export var ZERO = JSBI.BigInt(0);
export var ONE = JSBI.BigInt(1);
export var TWO = JSBI.BigInt(2);
export var THREE = JSBI.BigInt(3);
export var FIVE = JSBI.BigInt(5);
export var TEN = JSBI.BigInt(10);
export var _100 = JSBI.BigInt(100);
export var _9975 = JSBI.BigInt(9975);
export var _10000 = JSBI.BigInt(10000);
export var MaxUint256 = JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff");
export var SolidityType;
(function(SolidityType) {
    SolidityType["uint8"] = "uint8";
    SolidityType["uint256"] = "uint256";
})(SolidityType || (SolidityType = {}));
var _obj2;
export var SOLIDITY_TYPE_MAXIMA = (_obj2 = {}, _define_property(_obj2, SolidityType.uint8, JSBI.BigInt("0xff")), _define_property(_obj2, SolidityType.uint256, JSBI.BigInt("0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff")), _obj2);
export var WETH9 = _define_property({}, ChainId.DOGECHAIN, new Token(ChainId.DOGECHAIN, "0xB7ddC6414bf4F5515b52D8BdD69973Ae205ff101", 18, "DOGE", "Wrapped DOGE", "https://dogechain.dog/"));
var _obj3;
export var WBNB = (_obj3 = {}, _define_property(_obj3, ChainId.BSC, new Token(ChainId.BSC, "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", 18, "WBNB", "Wrapped BNB", "https://www.binance.org")), _define_property(_obj3, ChainId.BSC_TESTNET, new Token(ChainId.BSC_TESTNET, "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd", 18, "WBNB", "Wrapped BNB", "https://www.binance.org")), _obj3);
var _obj4;
export var WNATIVE = (_obj4 = {}, _define_property(_obj4, ChainId.BSC, WBNB[ChainId.BSC]), _define_property(_obj4, ChainId.BSC_TESTNET, WBNB[ChainId.BSC_TESTNET]), _define_property(_obj4, ChainId.DOGECHAIN, WETH9[ChainId.DOGECHAIN]), _obj4);
var _obj5;
export var NATIVE = (_obj5 = {}, _define_property(_obj5, ChainId.BSC, {
    name: "Binance Chain Native Token",
    symbol: "BNB",
    decimals: 18
}), _define_property(_obj5, ChainId.BSC_TESTNET, {
    name: "Binance Chain Native Token",
    symbol: "tBNB",
    decimals: 18
}), _define_property(_obj5, ChainId.DOGECHAIN, {
    name: "DogeChain Native Token",
    symbol: "DOGE",
    decimals: 18
}), _obj5);
